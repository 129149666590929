import "./Header.scss";
import React from "react";
import { get, set } from "lodash";
import { BrowserRouter as Router, Link, RouteComponentProps } from "react-router-dom";
import { IRootState } from "../../store/reducers";
import { connect } from "react-redux";
import { Container, Row, Col } from 'reactstrap'
import { BASE_API_URL } from "../../constants/EnvConstants";
import { fetchAffiliateLogo } from "../../store/reducers/affiiate";

export interface IHeaderState {
  affiliateId?: number;
}

export interface IHeaderProps extends StateProps, DispatchProps, RouteComponentProps {
}

class Header extends React.Component<IHeaderProps, IHeaderState> {
  constructor(props) {
    super(props);

  }

    render() {
      var logoAffiliateUrl = undefined;

      const { affiliateLogoId, affiliateLogoLoading } = this.props;
      if (affiliateLogoId){
        logoAffiliateUrl = BASE_API_URL + "affiliates/" + affiliateLogoId + "/affiliateLogo";
      }

      return (<div></div>
        // <header className="main-header">
        //     <Container>
        //       <Row>
        //           <Col sm="7" className="header-widget">
        //             <div id="text-2" className="fwidget et_pb_widget widget_text">
        //                 <img src="https://truspecialty.wpengine.com/wp-content/uploads/2022/04/TruSpecialty.svg" alt="" title="" className="disLogo" />
        //             </div>
        //           </Col>
        //           <Col sm="2" className="header-widget">
        //            &nbsp;
        //           </Col>
        //       </Row>
        //     </Container>
        // </header>
      );
    }
  }

  const mapStateToProps = (state: IRootState) => ({
    affiliateLogoId: state.affiliate.affiliateLogoId,
    affiliateLogoLoading: state.affiliate.loading
  });

  const mapDispatchToProps = {
    fetchAffiliateLogo,
  };

  type StateProps = ReturnType<typeof mapStateToProps>;
  type DispatchProps = typeof mapDispatchToProps;

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header);

