import React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";

import "./SimpleDatePickerField.scss";

export interface ISimpleDatePickerProps<FieldValue, El extends HTMLElement> extends FieldProps<FieldValue, El> {
  label?: string;
}

export interface ISimpleDatePickerRenderProps
  extends ISimpleDatePickerProps<Date, HTMLElement>,
    FieldRenderProps<Date, HTMLElement> {}

const StyleKeyboardDatePicker = withStyles({
  root: {
    background: "transparent"
  }
})(KeyboardDatePicker);

export class SimpleDatePicker extends React.Component<ISimpleDatePickerRenderProps> {
  render() {
    const { input, meta, label, disabled } = this.props;

    const value = input.value ? input.value : null;

    /**
     * Open bug about the emptyLabel warning https://github.com/mui-org/material-ui-pickers/issues/1093
     */
    return (
      <div 
        className={
          disabled
            ? "single-select-field-disabled"
            : meta.touched && meta.error
            ? "dob__wrap__error dob__wrap"
            : "dob__wrap"
        }>
        <StyleKeyboardDatePicker
          emptyLabel={"mm-dd-yyyy"}
          {...input}
          label={label}
          clearable
          required
          value={value}
          format="MM-DD-YYYY"
          onChange={(date, value) => {
            const dateString = date ? date.format() : null;
            input.onChange(dateString as any);
          }}
          onError={error => console.log("error", error)}
        />
        {meta.touched && meta.error && <span>{meta.error}</span>}
      </div>
    );
  }
}

export interface ISimpleDatePickerFieldProps extends ISimpleDatePickerProps<Date, HTMLElement>, FieldProps<Date,HTMLElement> 
{
  isRequired?: Boolean;
}

export default function SimpleDatePickerField(props: ISimpleDatePickerFieldProps) {
  return <Field component={SimpleDatePicker} {...props} />;
}
