import axios from "axios";

import { PENDING, FULFILLED, REJECTED } from "./action-type.util";

const ACTION_TYPES = {
  FETCH_AFFILIATE_LOGO: "affiliate/FETCH_AFFILIATE_LOGO"
};

const initialState = {
  loading: false,
  affiliateLogoId: undefined,
};

export type AffiliateState = Readonly<typeof initialState>;

/**
 * Affiliate Reducer
 */
export default (state: AffiliateState = initialState, action): AffiliateState => {
  switch (action.type) {
    case PENDING(ACTION_TYPES.FETCH_AFFILIATE_LOGO):{
      return {
        ...state,
        affiliateLogoId: undefined,
        loading: true
      };
    }
    
    case FULFILLED(ACTION_TYPES.FETCH_AFFILIATE_LOGO): {
      return {
        ...state,
        affiliateLogoId: action.payload.data,
        loading: false
      };
    }

    case REJECTED(ACTION_TYPES.FETCH_AFFILIATE_LOGO): {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

export const fetchAffiliateLogo = (affiliateId: number) => ({
  type: ACTION_TYPES.FETCH_AFFILIATE_LOGO,
  payload: axios.get(`affiliates/${affiliateId}/logo`)
});

