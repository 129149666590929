import axios from "axios";
import { get } from "lodash";
import { PENDING, FULFILLED, REJECTED } from "./action-type.util";
import { IOption } from "../models/option.model";
import { defaultProposal } from "../models/proposal.model";
import { defaultCondition } from "../models/client.model";
import { roundCurrency } from "../../util/utils.currency";
import { string } from "prop-types";

const ACTION_TYPES = {
  CLIENT_FETCH_OPTIONS: "client/FETCH_OPTIONS",
  CASEDESIGN_FETCH_OPTIONS: "casedesign/FETCH_OPTIONS",
  PRODUCT_FETCH_PRODUCTTYPES: "product/FETCH_PRODUCTTYPES",
  FETCH_RIDERS: "casedesign/FETCH_RIDERS"
};

const productOptions = {
  selectProductTypes: []
};

const clientOptions = {
  selectGenderList: [],
  selectPrefixList: [],
  selectTobaccoTypeList: [],
  selectTobaccoFrequencyList: [],
  selectMedicationFrequencyList: [],
  selectCredentialTypeList: [],
  selectGovernmentTypeList: [],
  selectStateList: [],
  selectOccupationStatusList: [],
  selectPaidByTypeList: []
};

const caseDesignOptions = {
  selectBPList: [],
  selectEPList: [],
  selectPayorList: [],
  selectRiderList: []
};

const initialState = {
  isSubmitting: false,
  isSavingNext: false,
  isSavingPrevious: false,

  productOptions: productOptions,
  clientOptions: clientOptions,
  caseDesignOptions: caseDesignOptions,
  caseDesignRiderOptions: {
    productID: null,
    productName: "",
    riders: []
  }
};

export type ClientsOptions = Readonly<typeof clientOptions>;
export type ProposalCaseDesignOptions = Readonly<typeof caseDesignOptions>;
export type ClientOptionsState = Readonly<typeof initialState>;

/**
 * ClientOptions Reducer
 */
export default (state: ClientOptionsState = initialState, action): ClientOptionsState => {
  switch (action.type) {
    case PENDING(ACTION_TYPES.CLIENT_FETCH_OPTIONS): {
      return {
        ...state
      };
    }
    case FULFILLED(ACTION_TYPES.CLIENT_FETCH_OPTIONS): {
      var clientOptions = action.payload.data;
      return {
        ...state,
        clientOptions
      };
    }
    case REJECTED(ACTION_TYPES.CLIENT_FETCH_OPTIONS): {
      return {
        ...state,
        isSubmitting: false,
        isSavingNext: false,
        isSavingPrevious: false
      };
    }

    case PENDING(ACTION_TYPES.FETCH_RIDERS):
    case PENDING(ACTION_TYPES.CASEDESIGN_FETCH_OPTIONS): {
      return {
        ...state
      };
    }
    case FULFILLED(ACTION_TYPES.CASEDESIGN_FETCH_OPTIONS): {
      var caseDesignOptions = action.payload.data;

      return {
        ...state,
        caseDesignOptions
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_RIDERS): {
      const productID = action.payload.data.productID;
      const productName = action.payload.data.productName;
      const riders = action.payload.data.riders;
      return {
        ...state,
        caseDesignRiderOptions: {
          productID,
          productName,
          riders
        }
      };
    }

    case REJECTED(ACTION_TYPES.CASEDESIGN_FETCH_OPTIONS): {
      return {
        ...state,
        isSubmitting: false,
        isSavingNext: false,
        isSavingPrevious: false
      };
    }

    case FULFILLED(ACTION_TYPES.PRODUCT_FETCH_PRODUCTTYPES): {
      var productOptions = action.payload.data;
      return {
        ...state,
        productOptions
      };
    }
    case PENDING(ACTION_TYPES.PRODUCT_FETCH_PRODUCTTYPES): {
      return {
        ...state
      };
    }
    case REJECTED(ACTION_TYPES.PRODUCT_FETCH_PRODUCTTYPES): {
      return {
        ...state
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

/**
 * Fetch dropdown options for creating a new potential proposal.
 */
export const fetchClientOptions = () => ({
  type: ACTION_TYPES.CLIENT_FETCH_OPTIONS,
  meta: { },
  payload: axios.get("brokerspublic/clientoptions")
});

export const fetchCaseDesignOptions = () => ({
  type: ACTION_TYPES.CASEDESIGN_FETCH_OPTIONS,
  meta: { },
  payload: axios.get("brokerspublic/casedesignoptions")
});

export const fetchProductTypeOptions = () => ({
  type: ACTION_TYPES.PRODUCT_FETCH_PRODUCTTYPES,
  meta: { },
  payload: axios.get("brokerspublic/producttypes")
});

export const fetchRiders = (productTypeID: number) => ({
  type: ACTION_TYPES.FETCH_RIDERS,
  payload: axios.get(`products/${productTypeID}/riders`, { params: { id: productTypeID } })
});
