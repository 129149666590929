import { IBroker, defaultBroker } from "./broker.model";
import { IClient, defaultClient } from "./client.model";
import * as S from "../../constants/StringConstants";

/**
 *  ProposalRecords represent a single entry in the proposals table.
 */
export interface IProposalRecord {
  timeSpan: string;
  proposalID: number;
  revisionNumber: number;
  branch: string;
  rbm: string;
  affiliate: string;
  memberCompany: string;
  brokerName: string;
  clientName: string;
  specialistName: string;
  status: string;
}

export const defaultValue: Readonly<IProposalRecord> = {
  timeSpan: "",
  proposalID: null,
  revisionNumber: null,
  branch: "",
  rbm: "",
  affiliate: "",
  memberCompany: "",
  brokerName: "",
  clientName: "",
  specialistName: "",
  status: ""
};

export interface IProposal {
  proposalID: number;
  proposalGroupID: number;
  broker: IBroker;
  client: IClient;
  caseDesign: any;
  organization: string;
  specialistID: number;
  productTypeID: number;
  epTypeID: number;
  bpTypeID: number;
  payorTypeID: number;
  tobaccoTypeID: number;
  tobaccoFrequencyTypeID: number;
  isMaxBenefit: boolean;
  statusTypeID: number;
  originationTypeID: number;
}

export const defaultProposal: Readonly<IProposal> = {
  proposalID: null,
  proposalGroupID: null,
  broker: defaultBroker,
  client: defaultClient,
  caseDesign: null,
  organization: S.PDO_QUOTE_ENGINE,
  specialistID: undefined,
  productTypeID: undefined,
  epTypeID: undefined,
  bpTypeID: undefined,
  payorTypeID: undefined,
  tobaccoTypeID: 1,
  tobaccoFrequencyTypeID: 1,
  isMaxBenefit: true,
  statusTypeID: 2,
  originationTypeID: 1
};

export const overviewDefaults = {
  proposalID: null,
  proposalGroupID: null,
  broker: defaultBroker,
  clients: [defaultClient]
};
