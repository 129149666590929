import axios from "axios";

import { PENDING, FULFILLED, REJECTED } from "./action-type.util";

const ACTION_TYPES = {
  FETCH_OPTIONS: "brokers/FETCH_OPTIONS",
  FETCH_AFFILIATE: "brokers/FETCH_AFFILIATE",
  FETCH_BROKER_PRIMARY_EMAIL: "brokers/FETCH_BROKER_PRIMARY_EMAIL",
};

const initialState = {
  loading: false,
  companyAffiliate: undefined,
  isAfiliateRequestInProgress: false,
  affiliateList: [],
  branchList: [],
  companyList: [],
  selectStateList: [],
  selectOccupationStatusList: [],
  rbmList: [],
  hearAboutUsList: [],
  brokerPrimaryEmailValidating: false,
  existentBrokerIdByEmail: undefined,
  existentBrokerFirstNameByEmail: undefined,
  existentBrokerLastNameByEmail: undefined
};

export type BrokerBuilderState = Readonly<typeof initialState>;

/**
 * BrokerBuilder Reducer
 */
export default (state: BrokerBuilderState = initialState, action): BrokerBuilderState => {
  switch (action.type) {
    case PENDING(ACTION_TYPES.FETCH_AFFILIATE):{
      return {
        ...state,
        companyAffiliate: undefined,
        loading: true,
        isAfiliateRequestInProgress: true
      };
    }
    case PENDING(ACTION_TYPES.FETCH_OPTIONS): {
      return {
        ...state,
        companyAffiliate: undefined,
        loading: true
      };
    }
    case PENDING(ACTION_TYPES.FETCH_BROKER_PRIMARY_EMAIL): {
      return {
        ...state,
        brokerPrimaryEmailValidating: true,
        existentBrokerIdByEmail: undefined
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_OPTIONS): {
      const affiliateList = action.payload.data.selectAffiliateList;
      const branchList = action.payload.data.selectBranchList;
      const rbmList = action.payload.data.selectRBMList;
      const companyList = action.payload.data.selectCompanyList;
      const selectStateList = action.payload.data.selectStateList;
      const selectOccupationStatusList = action.payload.data.selectOccupationStatusList;
      const hearAboutUsList = action.payload.data.selectHearAboutUsList;

      return {
        ...state,
        companyAffiliate: undefined,
        affiliateList,
        companyList,
        branchList,
        rbmList,
        selectStateList,
        selectOccupationStatusList,
        hearAboutUsList,
        loading: false,
        brokerPrimaryEmailValidating: false,
        existentBrokerIdByEmail: undefined
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_AFFILIATE): {
      const companyAffiliate = action.payload.data;
      return {
        ...state,
        companyAffiliate,
        isAfiliateRequestInProgress: false
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_BROKER_PRIMARY_EMAIL): {
      const existentBroker = action.payload.data;
      return {
        ...state,
        brokerPrimaryEmailValidating: false,
        existentBrokerIdByEmail: existentBroker.brokerID
      };
    }

    case REJECTED(ACTION_TYPES.FETCH_OPTIONS): {
      return {
        ...state,
        loading: false
      };
    }

    case REJECTED(ACTION_TYPES.FETCH_BROKER_PRIMARY_EMAIL): {
      return {
        ...state,
        brokerPrimaryEmailValidating: false,
        existentBrokerIdByEmail: undefined
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

export const fetchBrokerOptions = (campaignID: number) => {
  var url = `brokerspublic/new`;
  if(campaignID) {
    url += "/" + campaignID;
  }
  return {
    type: ACTION_TYPES.FETCH_OPTIONS,
    payload: axios.get(url)
  };
};

export const fetchCompanyAffiliate = (companyID: number) => ({
  type: ACTION_TYPES.FETCH_AFFILIATE,
  payload: axios.get(`companies/${companyID}/affiliate`)
});

export const fetchBrokerByPrimaryEmail = (email: string) => ({
  type: ACTION_TYPES.FETCH_BROKER_PRIMARY_EMAIL,
  payload: axios.post(`validatePrimaryEmailExists`, { email })
});
