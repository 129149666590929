/**
 *  StorageConstants.ts
 *  Disability Insurance Services
 *
 *  Created by Jeremy Moyers on Thu Apr 8 13:02:42 2019
 *  Copyright Seamgen, LLC. All rights reserved.
 */

 /**
  * Session Keys
  */
export const ACCESS_TOKEN = "accessTokenKey"
export const REFRESH_TOKEN = "refreshTokenKey"
export const BROKERID = "brokerId"

