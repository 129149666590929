import { combineReducers } from "redux";

import auth, { AuthState } from "./authentication";
import proposals, { ProposalResourcesState } from "./proposals";
import productBuilder, { ProductBuilderState } from "./productBuilder";
import proposalOptions, { ProposalOptionsState } from "./proposalOptions";
import clientOptions, { ClientOptionsState } from "./clientOptions";
import brokers, { BrokerResourcesState } from "./brokers";
import companies, { CompanyResourcesState } from "./companies";
import brokerBuilder, { BrokerBuilderState } from "./brokerBuilder";
import affiliate, { AffiliateState } from "./affiiate";

export interface IRootState {
  readonly auth: AuthState;
  readonly proposals: ProposalResourcesState;
  readonly proposalOptions: ProposalOptionsState;
  readonly clientOptions: ClientOptionsState;
  readonly productBuilder: ProductBuilderState;
  readonly brokers: BrokerResourcesState;
  readonly companies: CompanyResourcesState;
  readonly brokerBuilder: BrokerBuilderState;
  readonly affiliate: AffiliateState;
}

const rootReducer = combineReducers<IRootState>({
  auth,
  proposals,
  proposalOptions,
  clientOptions,
  productBuilder,
  brokers,
  companies,
  brokerBuilder,
  affiliate
});

export default rootReducer;
