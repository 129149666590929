import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, Link, Redirect } from "react-router-dom";
import { Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { Container, Row, Col, Spinner } from "reactstrap";
import { get } from "lodash";

import { IRootState } from "../../store/reducers";
import TextField from "../../components/Form/TextField/TextField";
import { validateEmail } from "../../store/reducers/authentication";
import * as S from "../../constants/StringConstants";
import "./Login.scss";
import Welcome from '../Broker/Welcome';
import Header from '../Layout/Header';
import FooterShort from '../Layout/FooterShort';
import HeaderHome from '../Layout/HeaderHome';

export interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps {}

export class Login extends React.Component<ILoginProps> {

  componentDidMount(): void {
    this.onSubmit({ email : 'info@truspeciatly.com'});
  }

  onSubmit = async values => {
    const { email } = values;
      var authResult = await this.props.validateEmail(email);

      var failed = get(authResult, "failed");
      if (failed) {
        return {
          [FORM_ERROR]: get(authResult, "error")
        };
      } else {
        var brokerId = get(authResult, "brokerId");
        const campaignId = get(this.props.match.params, "campaignId");

        let brokerUrl = "/broker/" + brokerId;
        if (campaignId){
          brokerUrl += "/campaign/" + campaignId;
        }
        this.props.history.push(brokerUrl);
      }

  };

  render() {
    const { location, isAuthenticated } = this.props;
    const { from } = location.state || { from: { pathname: "/", search: location.search } };

    if (isAuthenticated) {
      return <Redirect to={from} />;
    }

    return (
      <div>
        <HeaderHome {...this.props} />

        <section className="login">
          <Container fluid>
            <Row>
              <Col>
                <div className="login__form-wrap">
                  {/* <h1>{S.LOGIN_SIGN_IN}</h1> */}

                  <FinalForm
                    onSubmit={this.onSubmit}
                    render={({ handleSubmit, invalid, submitFailed, submitError, submitting }) => {
                      return (
                        <form noValidate className="login__form" onSubmit={handleSubmit}>
                          {submitFailed && <div className="not-valid">{submitError}</div>}

                          <TextField
                            label={S.EMAIL_LABEL}
                            name="email"
                            type="email"
                            disabled
                            defaultValue="info@truspeciatly.com"
                            light={false}
                            validate={value => (value ? undefined : S.FORM_FIELD_REQUIRED)}
                          />

                          <button type="submit" disabled={submitting}>
                            {submitting ? <Spinner color="white" /> : S.LOGIN_SIGN_IN}
                          </button>
                        </form>
                      );
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <FooterShort />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }: IRootState) => ({
  isAuthenticated: auth.isAuthenticated
});
const mapDispatchToProps = { validateEmail };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
