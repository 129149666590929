/**
 * utils.currency.ts
 * Disability Insurance Services
 *
 * Created by Jeremy Moyers
 */

/**
 * Round the specified number to the hundreds place.
 * @param {number} value - the number value to round.
 */
export const roundCurrency = (value: number): number => {
  if (value === undefined) {
    return;
  }
  return parseFloat(value.toFixed(2));
};

export const formatUSD = (value: number): string => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });

  return formatter.format(value);
};
