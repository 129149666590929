import axios from "axios";

import { PENDING, FULFILLED, REJECTED } from "./action-type.util";

export const ACTION_TYPES = {
  FETCH_OPTIONS: "productBuilder/FETCH_OPTIONS",
  CREATE_PRODUCT: "productBuilder/CREATE_PRODUCT"
};

const initialState = {
  loading: false,
  productTypeList: [],
  carrierTypeList: [],
  occupationClassTypeList: [],
  designTypeList: [],
  renewabilityTypeList: [],
  bpTypeList: [],
  epTypeList: [],
  ownOccTypeList: [],
  residualTypeList: [],
  minimumResidualBenefitPayableTypeList: [],
  futureInsurabilityOptionsTypeList: [],
  airTypeList: [],
  colaTypeList: [],
  mentalNervousLimitationTypeList: [],
  catastrophicBenefitRiderTypeList: [],
  uniqueProvisionsTypeList: []
};

export type ProductBuilderState = Readonly<typeof initialState>;

/*
 * Product Builder Reducer
 */
export default (state: ProductBuilderState = initialState, action): ProductBuilderState => {
  switch (action.type) {
    // Pending Actions
    case PENDING(ACTION_TYPES.FETCH_OPTIONS):
    case PENDING(ACTION_TYPES.CREATE_PRODUCT): {
      return {
        ...state,
        loading: true
      };
    }

    // Fulfilled Actions
    case FULFILLED(ACTION_TYPES.FETCH_OPTIONS): {
      const dropdownOptions = action.payload.data;

      return {
        ...state,
        ...dropdownOptions
      };
    }

    case FULFILLED(ACTION_TYPES.CREATE_PRODUCT): {
      return {
        ...state,
        loading: false
      };
    }

    // Rejected Actions
    case REJECTED(ACTION_TYPES.FETCH_OPTIONS): {
      return {
        ...state,
        loading: false
      };
    }

    case REJECTED(ACTION_TYPES.CREATE_PRODUCT): {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

/**
 * Populate the Product Builder drop downs with options.
 */
export const fetchProductBuilderOptions = () => ({
  type: ACTION_TYPES.FETCH_OPTIONS,
  payload: axios.get("products/builder")
});

/**
 * Create a new product with the specified product options.
 */
export const createProduct = payload => ({
  type: ACTION_TYPES.CREATE_PRODUCT,
  payload: axios.post("products", payload)
});
