import "./Footer.scss";
import React from "react";
import { IRootState } from "../../store/reducers";
import { connect } from "react-redux";
import { Container, Row, Col } from 'reactstrap'

export interface IFooterState {
  }

  export interface IFooterProps extends StateProps, DispatchProps {
  }

  class Footer extends React.Component<IFooterProps, IFooterState> {
    constructor(props) {
      super(props);

      this.state = {
        isLoggedIn: false
      };
    }

    render() {

      return (<div></div>
        // <footer>
        //     <Container>
        //       <Row className="first-footer">
        //         <Col sm="6" className="footer-widget">
        //         </Col>
        //         <Col sm="3" className="footer-widget">
        //           <div id="custom_html-2" className="widget_text fwidget et_pb_widget widget_custom_html">
        //               <div className="textwidget custom-html-widget">
        //                 <a id="phone-number" href="tel:619-584-2570">619-584-2570</a>
        //               </div>
        //           </div>
        //         </Col>
        //         <Col sm="3" className="footer-widget">
        //           <div id="custom_html-3" className="widget_text fwidget et_pb_widget widget_custom_html">
        //               <div className="textwidget custom-html-widget">
        //                 <a id="email" href="mailto:info@truspecialty.com">info@truspecialty.com</a>
        //               </div>
        //           </div>
        //         </Col>
        //       </Row>
            // </Container>
            //footer>
      );
    }
  }

  const mapStateToProps = (state: IRootState) => ({

  });

  const mapDispatchToProps = {
  };

  type StateProps = ReturnType<typeof mapStateToProps>;
  type DispatchProps = typeof mapDispatchToProps;

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Footer);

