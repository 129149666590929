import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, Link } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import Routes from "./routes";
import { IRootState } from "./store/reducers";
import * as K from "./constants/StorageConstants";

import "./styles/index.scss";

export interface IAppProps extends StateProps, DispatchProps, RouteComponentProps {}

export class App extends React.Component<IAppProps> {
  
  render() {
    return (
      <div className="app">
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }: IRootState) => ({
  ...auth
});

const mapDispatchToProps = {
  
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
