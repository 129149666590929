import * as S from "../../constants/StringConstants";

export interface IBroker {
  brokerID: 0;
  brokerName: string;
  preferredName: string;
  primaryPhone: string;
  primaryEmail: string;
  companyName: string;
  affiliateName: string;
  externalID: number;
  isSMSTextEnabled: boolean;
  brokerProduction: [
    {
      carrierType: string;
      premiumTotal: number;
    }
  ];
}

export const defaultBroker: Readonly<IBroker> = {
  brokerID: null,
  brokerName: "",
  preferredName: "",
  primaryPhone: "",
  primaryEmail: "",
  companyName: "",
  affiliateName: "",
  externalID: null,
  isSMSTextEnabled: false,
  brokerProduction: [
    {
      carrierType: undefined,
      premiumTotal: undefined
    }
  ]
};
