export interface IClient {
  firstName: string;
  lastName: string;
  email: string,
  conditions: ICondition[];
}

export interface ICondition {
  conditionName: string;
  medications: IMedication[];
}

export interface IMedication {
  name: string;
  dose: string;
  frequencyTypeID: number;
}

export const defaultMedication: Readonly<IMedication> = {
  name: undefined,
  dose: undefined,
  frequencyTypeID: 1
};

export const defaultCondition: Readonly<ICondition> = {
  conditionName: undefined,
  medications: [defaultMedication]
};

export const defaultClient: Readonly<IClient> = {
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  conditions: [defaultCondition]
};
