import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { get } from "lodash";

import { IRootState } from "../../store/reducers";
import "./QuoteWizard.scss";
import ClientBuilder from '../Broker/ClientBuilder';

export interface IQuoteWizardProps extends StateProps, DispatchProps, RouteComponentProps {
}

export class QuoteWizard extends React.Component<IQuoteWizardProps> {
  render() {
    const brokerID = get(this.props.match.params, "id", "new");
    var clientID = get(this.props.match.params, "client", undefined);
    if(!clientID && this.props.match.url.startsWith("/client")){
      clientID = "-1";
    }
    
    return <ClientBuilder {...this.props} brokerID={brokerID} clientID={clientID} />;
  }
}

const mapStateToProps = ({ auth }: IRootState) => ({
  isAuthenticated: auth.isAuthenticated
});
const mapDispatchToProps = { };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteWizard);
