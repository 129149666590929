import "./Header.scss";
import React from "react";
import { get, set } from "lodash";
import { BrowserRouter as Router, Link, RouteComponentProps } from "react-router-dom";
import { IRootState } from "../../store/reducers";
import { connect } from "react-redux";
import { Container, Row, Col } from 'reactstrap'
import { BASE_API_URL } from "../../constants/EnvConstants";
import { fetchAffiliateLogo } from "../../store/reducers/affiiate";

export interface IHeaderHomeState {
  affiliateId?: number;
}

export interface IHeaderHomeProps extends StateProps, DispatchProps, RouteComponentProps {
}

class HeaderHome extends React.Component<IHeaderHomeProps, IHeaderHomeState> {
  constructor(props) {
    super(props);

    this.state = {
      affiliateId: undefined
    };
  }

  async componentDidMount() {
    if (this.props.match) {
      const affiliateParam = get(this.props.match.params, "affiliateId");

      var affiliateId = undefined;
      if (affiliateParam && this.props.match.url.startsWith("/quote/")) {
        affiliateId = +affiliateParam.match(/(\d+)/)[0];
        this.setState(state =>
          {
            affiliateId
          });
        this.props.fetchAffiliateLogo(affiliateId);
      }
    }
  }

    render() {
      var logoAffiliateUrl = undefined;

      const { affiliateLogoId, affiliateLogoLoading } = this.props;
      if (affiliateLogoId){
        logoAffiliateUrl = BASE_API_URL + "affiliates/" + affiliateLogoId + "/affiliateLogo";
      }

      return (<div></div>
        // <header className="main-header">
        //     <Container>
        //       <Row>
        //           <Col sm="7" className="header-widget">
        //             <div id="text-2" className="fwidget et_pb_widget widget_text">
        //                 <img src="https://truspecialty.wpengine.com/wp-content/uploads/2022/04/TruSpecialty.svg" alt="" title="" className="disLogo" />
        //             </div>
        //           </Col>
        //           {!logoAffiliateUrl && !affiliateLogoLoading
        //           ? <React.Fragment>
        //             <Col sm="2" className="header-widget">
        //               {/* <div id="custom_html-3" className="widget_text fwidget et_pb_widget widget_custom_html">
        //                   <div className="textwidget custom-html-widget">
        //                       <a id="email" href="http://diservices.com">diservices.com</a>
        //                   </div>
        //               </div>  */}
        //             </Col>
        //             <Col sm="3" className="header-widget">
        //               <div id="custom_html-2" className="widget_text fwidget et_pb_widget widget_custom_html">
        //                   <div className="textwidget custom-html-widget">
        //                       <a id="phone-number" href="tel:619-584-2570">619-584-2570</a>
        //                   </div>
        //               </div>
        //             </Col>
        //           </React.Fragment>
        //           :
        //             <React.Fragment>
        //               <Col sm="4" className="header-widget">
        //                 &nbsp;
        //               </Col>
        //               <Col sm="1" className="header-widget">
        //                 <img src={logoAffiliateUrl} className="logoPreview" />
        //               </Col>
        //             </React.Fragment>
        //           }
        //       </Row>
        //     </Container>
        // </header>
      );
    }
  }

  const mapStateToProps = (state: IRootState) => ({
    affiliateLogoId: state.affiliate.affiliateLogoId,
    affiliateLogoLoading: state.affiliate.loading
  });

  const mapDispatchToProps = {
    fetchAffiliateLogo,
  };

  type StateProps = ReturnType<typeof mapStateToProps>;
  type DispatchProps = typeof mapDispatchToProps;

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(HeaderHome);

