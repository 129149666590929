import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Link, RouteComponentProps } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { get } from "lodash";

import { IRootState } from "../../store/reducers";
import TextField from "../../components/Form/TextField/TextField";
import * as S from "../../constants/StringConstants";
import "./QuoteSent.scss";

export interface IQuoteEngineProps extends StateProps, DispatchProps, RouteComponentProps {

}

export class QuoteSent extends React.Component<IQuoteEngineProps> {

  render() {
    const brokerID = get(this.props.match.params, "id", "new");
    const campaignId = get(this.props.match.params, "campaignId");
    let brokerUrl = "/broker/";
    if(this.props.match.params[1] === "client" || campaignId === "client"){
      brokerUrl = "/client/";
    }
    
    if(brokerID){
      brokerUrl = brokerUrl + brokerID;
    }
    if (campaignId && campaignId !== "client"){
      brokerUrl += "/campaign/" + campaignId;
    }

    return (
      <section className="quotesent">    
        <Container fluid>
          <Row>
            <Col>
                <div className="center">
                  <h1 className="red-text">{S.QUOTE_SENT_SUCCESS_MESSAGE}</h1>
                </div>
            </Col>
          </Row>
    
          <Row>
            <Col>
              <div className="center">
                <a className="buttonred" href={brokerUrl}>{S.NEW_QUOTE_CREATION}</a>
              </div>
            </Col>
          </Row>
    
        </Container>
      </section>
    );
  }
}

const mapStateToProps = ({ auth }: IRootState) => ({
  isAuthenticated: auth.isAuthenticated
});
const mapDispatchToProps = { };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteSent);
