/**
 * utils.defaultValues.ts
 * Disability Insurance Services
 *
 * Created by Jeremy Moyers on Wed May 17
 */

// The default value for Single Select option lists
// It has to be a string because HTML Javascript and React Final Form hate you.
export const defaultOptionValue = -1;

export const addDefaultOption = function(label, options){
  if(Array.isArray(options)){
    return [
      { key: label, value: defaultOptionValue, disabled: false },
      ...options
    ];
  }
  else {
    return [
      { key: label, value: defaultOptionValue, disabled: false }
    ];
  }
} 

export const isEmptyOrDefault = (value: any): boolean => {
  return !value || (value && value === defaultOptionValue || value === -1);
};

export const undefinedIfDefault = (value: any): any => {
  return value === defaultOptionValue || value === -1 ? undefined : value;
};
