import { IBroker } from "../models/broker.model";
import ResourceFactory from "./resource";

const resourceKey = "brokerspublic";
const resourceUrl = "brokerspublic";
const resourceIdKey = "brokerID";

const { reducer, fetchList, create, fetch, update } = ResourceFactory<IBroker>(resourceKey, resourceUrl, resourceIdKey);

export type BrokerResourcesState = ReturnType<typeof reducer>;
export const fetchBrokersList = fetchList;
export const createBroker = create;
export const fetchBroker = fetch;
export const updateBroker = update;
export default reducer;
